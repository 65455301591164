.stepContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 0 100px;
  color: #ffffff;
  background: var(--mediumBlueGradient);

  @media screen and (min-width: 480px) {
    padding: 50px 0 100px;
  }
}

.innerStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin-top: 30px;
  padding: 0 10px;

  h3,
  h4 {
    font-family: Poppins, sans-serif;
    color: #ffffff;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 0 25px rgba(47, 246, 255, 0.3);
  }

  h3 {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
  }

  p {
    margin-top: 10px;
    color: rgba(255, 255, 255, 0.85);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  span {
    color: var(--primaryPink);
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 340px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.docTypeContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;

  @media screen and (min-width: 450px) {
    gap: 20px;
  }
}

.docTypeBtn {
  width: 100%;
  height: 87px;
  padding: 20px;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #ffffff;
  border-radius: 20px;
  border: none;
  cursor: pointer;

  @media screen and (min-width: 300px) {
    width: 47%;
  }
}

.activeColour {
  background: radial-gradient(farthest-corner at 100% 0%, #00f4ff 0%, #0626d7 100%);
}

.inactiveColour {
  background: radial-gradient(100% 100% at 100% 0%, rgba(0, 244, 255, 0.2) 0%, rgba(6, 38, 215, 0.2) 100%);
}
