.dropdownContainer {
  position: relative;
  width: 100%;
  max-width: 440px;
  margin-top: 30px;
  margin-bottom: 60px;
  background-color: #ffffff;
  border-radius: 20px;
  cursor: pointer;

  button {
    width: 100%;
    height: 37px;
    padding-left: 40px;
    font-weight: 600;
    font-size: 16px;
    color: #4b4b4b;
    background-color: #ffffff;
    border-radius: 20px;
    text-align: left;
    border: none;
    cursor: pointer;
  }

  img {
    position: absolute;
    top: 3px;
    left: 14px;
    margin: 10px 0 40px;
  }
}

.dropdown {
  position: absolute;
  top: 0;
  width: 100%;
  height: 273px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #4b4b4b;
  background-color: #ffffff;
  border-radius: 20px;
  z-index: 3;
  cursor: pointer;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    box-shadow: 0 4px 40px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
  }

  img {
    top: 0;
    z-index: 10;
  }

  input {
    z-index: 9;
  }
}

.searchContainer {
  input {
    width: 100%;
    height: 37px;
    color: #979797;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    border-radius: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    padding-right: 10px;
  }

  label {
    display: none;
  }
}

.listContainer {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 30px;
  background-color: transparent;
  border-radius: 0 0 20px 20px;
}

.innerListContainer {
  display: flex;
  align-items: center;

  ul {
    position: absolute;
    top: 35px;
    width: 100%;

    // height: 240px;
    height: 220px;
    margin: 7px 20px 0 0;
    padding: 0 20px 30px;
    overflow-y: scroll;
    border-radius: 20px;
    z-index: 90;
  }

  ul::-webkit-scrollbar {
    width: 10px;
    border-radius: 26px;
  }

  ul::-webkit-scrollbar-track {
    margin: 18px 20px 10px 0;
    background-color: #dedede;
    border-radius: 26px;
  }

  ul::-webkit-scrollbar-thumb {
    height: 30px;
    background: radial-gradient(100% 100% at 100% 0%, #00f4ff 0%, #0626d7 100%);
    border-radius: 26px;
  }

  li {
    margin-top: 20px;
    list-style-type: none;

    &:hover {
      cursor: pointer;
    }
  }
}
