.sectionWrapper {
  position: relative;
  min-height: 600px;
}

.sectionBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/images/home-cover-background-small.svg");
  background-position: center -160px;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  @media screen and (min-width: 1100px) {
    background-image: url("../../../../assets/images/home-cover-background-medium.svg");
    background-position: center bottom;
  }

  @media screen and (min-width: 2400px) {
    background-image: url("../../../../assets/images/home-cover-background-large.svg");
  }
}

.contentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: clamp(110px, 9vw, 180px) clamp(20px, 4vw, 60px) 0;
  max-width: var(--homeWidthContainer);
  margin: 0 auto;

  @media screen and (min-width: 1100px) {
    flex-direction: initial;
    align-items: initial;
  }
}

.textContainer {
  color: #ffffff;
  max-width: 320px;
  padding-top: 60px;
  padding-bottom: 50px;

  @media screen and (min-width: 480px) {
    max-width: 690px;
  }

  @media screen and (min-width: 1100px) {
    padding-bottom: 100px;
  }

  h1 {
    font-size: clamp(38px, 4.5vw, 70px);
    line-height: 1.2;
    max-width: 250px;
    font-weight: 700;

    @media screen and (min-width: 480px) {
      max-width: 480px;
    }
  }

  p {
    font-size: 20px;
    margin-top: 30px;
    line-height: 1.7;
    max-width: 320px;

    @media screen and (min-width: 480px) {
      max-width: 455px;
    }
  }

  .buttonsTitle {
    margin-top: 50px;
    margin-bottom: 5px;
    font-size: 21px;
    font-weight: 600;
  }

  .googlePlayButton {
    width: clamp(160px, 44vw, 217px);
  }

  .appStoreButton {
    width: clamp(160px, 44vw, 216px);
  }
}

.imageContainer {
  position: relative;
  margin-top: 20px;

  @media screen and (min-width: 1100px) {
    margin-top: 70px;
  }

  @media screen and (min-width: 1250px) {
    margin-top: 0;
    margin-right: 20px;
  }
}

.image {
  width: 90%;
  aspect-ratio: 225 / 221;

  @media screen and (min-width: 1100px) {
    max-width: initial;
  }
}
