* {
    font-family: figtree, sans-serif;
    font-display: swap;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    overflow-y: scroll;
}

html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: figtree, Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    height: 100%;
    --purple: #9543fd;
    --purpleGradient: linear-gradient(90deg, #436cff 0%, #a800f7 100%);
    --purpleGradientInverted: radial-gradient(147.33% 335.64% at 92.93% 96.01%, #436cff 4.5%, #a800f7 100%);
    --homeWidthContainer: 1450px;
    --blueGradient: radial-gradient(100% 516.4% at 0% 0%, #49f1f7 0%, #0692c5 100%);
    --lightBlueGradient: radial-gradient(100% 516.4% at 0% 0%, #0692c5 0%, #49f1f7 100%);
    --mediumBlueGradient: linear-gradient(128.05deg, #0a46e0 10%, #00a4c9 80%);
    --darkBlueGradient: radial-gradient(73.16% 257.01% at 25.89% 54.51%, #436cff 4.5%, #1524a5 100%);
    --buttonBoxShadow: 3px 3px 20px #2725a626, inset 0 4px 4px #ffffff40, inset -4px -4px 10px #1407ae40;
    --orangeGradient: linear-gradient(79.86deg, #fc4a1a 2.38%, #f7b733 85.79%);
    --faqsBlueGradient: linear-gradient(80.05deg, #0a46e0 10.49%, #1ed6ff 79.98%);
}

main {
    flex: 1;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    border: none;
    background: transparent;
    color: inherit;
    cursor: pointer;
    transition: box-shadow 100ms linear;
}

input {
    outline: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    appearance: textfield;
}

textarea {
    resize: none;
    outline: 0;
}
