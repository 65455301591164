.indicatorContainer {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 20px 0 40px;
  padding: 0;
  filter: drop-shadow(0 0 25px rgba(47, 246, 255, 0.85));

  @media screen and (min-width: 900px) {
    max-width: 590px;
  }
}

.flipImg {
  transform: scaleX(-1);
}

.btn {
  margin: 0 5px;
  color: #2ff6ff;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
}

.btnBack {
  cursor: pointer;
}

.stepContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #2ff6ff;
}

.filledDot {
  background-color: #2ff6ff;
}

.line {
  width: calc(100% / 4);
  height: 1px;
}

.solid {
  border-top: 2px solid #2ff6ff;
}

.dashed {
  border-top: 2px dashed #2ff6ff;
}

.end {
  height: 3px;
  border: none;
  background-image: linear-gradient(to right, #00f4ff, #0b1b4f);
}
