.contentWrapper {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  max-width: var(--homeWidthContainer);
  margin: 0 auto;
  padding: 60px 30px 30px;

  @media screen and (min-width: 1100px) {
    flex-direction: initial;
  }
}

.textContainer {
  width: 100%;
  max-width: 590px;
  margin-top: 20px;

  @media screen and (min-width: 1100px) {
    margin-top: clamp(50px, 9vw, 150px);
  }

  h2 {
    font-size: clamp(30px, 6.5vw, 40px);
    line-height: 1;
    background: radial-gradient(73.16% 257.01% at 25.89% 54.51%, #436cff 4.5%, #1524a5 100%);
    color: transparent;
    background-clip: text;
  }

  p {
    font-size: clamp(16px, 3.5vw, 20px);
    margin-top: 40px;
    max-width: 520px;
    line-height: 1.6;
    color: #52687e;
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  max-width: 760px;
  display: flex;
  justify-content: center;
}

.image {
  width: 100%;
  max-width: 600px;
  height: auto;
  aspect-ratio: 826 / 654;
  margin-top: 40px;

  @media screen and (min-width: 1100px) {
    margin-top: initial;
    max-width: initial;
  }
}
