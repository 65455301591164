.container {
  position: relative;
  width: 100%;
  padding: 150px 0 130px;

  @media screen and (min-width: 375px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: clamp(40px, 5.5vw, 60px);
  background: var(--orangeGradient);
  color: transparent;
  background-clip: text;
  text-align: center;
}

.subtitle {
  width: 100%;
  max-width: 308px;
  margin: 20px auto 0;
  font-weight: 700;
  font-size: clamp(18px, 3.5vw, 20px);
  line-height: 22px;
  text-align: center;
  color: #52687e;
}

.contentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 26px;
}

.sectionBackground {
  position: absolute;
  width: 100%;
  height: clamp(53%, 60vw, 70%);
  left: 0;
  bottom: 0;
  background-image: url("../../../assets/images/contactUs-background-mobile.svg");
  background-size: 100% 100%;
  z-index: -1;

  @media screen and (min-width: 800px) {
    background-size: 120% 100%;
  }

  @media screen and (min-width: 1200px) {
    background-image: url("../../../assets/images/contactUs-background-desktop.svg");
  }

  @media screen and (min-width: 1550px) {
    background-size: 100% 100%;
  }
}

.formWrapper {
  width: 100%;
  max-width: 524px;
  padding: 21px;
  background-color: #ffffff;
  box-shadow: 0 4px 33px rgba(4, 12, 83, 0.25);
  border-radius: clamp(15px, 5vw, 20px);

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 438px;
    margin-left: auto;
    margin-right: auto;
  }
}

.btn {
  width: 188px;
  height: 54px;
  margin: 30px 0 20px;
  font-weight: 600;
  font-size: 21px;
  color: #ffffff;
  background: var(--orangeGradient);
  box-shadow: var(--buttonBoxShadow);
  border-radius: 42px;
}

.innerCanContainer {
  width: 100%;
  max-width: 428px;
  margin: 0 auto;
}

.recaptchaContainer {
  margin-top: 20px;
}

.errorMessage {
  display: block;
  margin-top: 3px;
  margin-left: 15px;
  font-size: 11px;
  color: red;
}

.correctMessage {
  display: block;
  margin-top: 10px;
  margin-left: 15px;
  font-size: 11px;
  color: green;
}

.headQuartersContainer {
  width: 100%;
  max-width: 430px;
  margin: 62px auto 0;
  padding-left: 4px;
  padding-right: 4px;
  color: #ffffff;
  font-family: Poppins, sans-serif;

  h2 {
    max-width: 322px;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
  }
}

.headQuarterAddress {
  display: flex;
  flex-direction: column;
  margin: 20px 0 10px;
  font-weight: 700;
  font-size: 21px;
  line-height: 30px;
}

.emailAddress {
  margin-top: 8px;
  font-weight: 400;
  font-size: clamp(16px, 10vw, 20px);
}

.socialNetworksColumn {
  width: 100%;
  margin: 20px auto 30px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 30px;

  svg {
    fill: #ff6e46;
    width: 30px;
    transition: transform 50ms;
  }

  svg:hover {
    transform: scale(1.15);
  }
}

@media screen and (min-width: 1200px) {
  .contentBox {
    position: relative;
    flex-direction: row;
    align-items: flex-end;
    margin: 64px auto 20px;
  }

  .formWrapper {
    order: -1;
    max-width: 524px;
  }

  .btn {
    margin-top: 40px;
  }

  .innerCanContainer {
    max-width: 270px;
  }

  .socialNetworksColumn {
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .headQuartersContainer {
    position: absolute;
    bottom: 0;
    left: 935px;
    max-width: 300px;
    margin-top: 0;
  }
}

@media screen and (min-width: 1350px) {
  .contentBox {
    width: 524px;
    max-width: 524px;
    min-height: 581px;
  }

  .headQuartersContainer {
    left: 100%;
    margin-left: 50px;
  }

  .socialNetworksColumn {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1780px) {
  .headQuartersContainer {
    max-width: 430px;
  }
}
