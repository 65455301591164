.pageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 0 100px;
  color: #ffffff;
  background: var(--mediumBlueGradient);

  @media screen and (min-width: 480px) {
    padding: 50px 0 100px;
  }
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin-top: 30px;
  padding: 0 10px;
}

.checkIcon {
  width: 120px;
  aspect-ratio: 1 / 1;
  height: auto;
}

.pageTitle {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.pageMessage {
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
