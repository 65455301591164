.sectionWrapper {
  position: relative;
  width: 100%;
  padding: 20px 20px 0;
  margin-bottom: clamp(-55px, -12vw, -35px);
  height: fit-content;

  @media screen and (min-width: 500px) {
    margin-bottom: clamp(-80px, -14vw, -35px);
  }

  /*   @media screen and (min-width: 600px) {
    margin-bottom: clamp(-120px, -16vw, -35px);
  } */

  @media screen and (min-width: 710px) {
    margin-bottom: 5px;
  }

  @media screen and (min-width: 1100px) {
    margin-bottom: -90px;
  }
}

.sectionBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../../../../assets/images/home-bottom-background-small.svg");
  background-size: 100% 100%;

  @media screen and (min-width: 1100px) {
    background-image: url("../../../../assets/images/home-bottom-background-medium.svg");
  }
}

.contentWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 0 50px;
  padding: 10px 0 0;
  max-width: var(--homeWidthContainer);
  margin: 0 auto;

  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  width: 100%;
  max-width: 380px;
  align-self: center;
  padding: 30px 30px 0;

  @media screen and (min-width: 1100px) {
    padding-top: 30px;
    align-items: flex-start;
  }

  h2 {
    font-size: 40px;
    line-height: 1.3;
    margin-bottom: 20px;
    max-width: 180px;
  }

  p {
    font-size: clamp(18px, 3.5vw, 20px);
    width: 100%;
    line-height: 1.6;
    text-align: center;

    @media screen and (min-width: 1100px) {
      text-align: left;
    }
  }

  .button {
    margin-top: 40px;
    background: #ffffff;
    padding: 12px 30px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 21px;

    span {
      background: var(--darkBlueGradient);
      color: transparent;
      background-clip: text;
    }
  }
}

.webScreensWrapper {
  position: relative;
  width: 100%;
  max-width: 690px;
  padding: 0 0 30px;
  aspect-ratio: 884 / 735;
  margin-top: 50px;

  @media screen and (min-width: 1100px) {
    width: 70%;
  }
}

.websScreens {
  width: 100%;
  height: auto;
}
