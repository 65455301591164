.btn {
  width: 100%;
  height: 56px;
  min-width: 144px;
  max-width: 220px;
  min-height: 50px;
  max-height: 50px;
  color: #ffffff;
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  padding: 6px 16px;
}

.redBtn {
  margin-top: 50px;
  padding: 14.5px 29.5px;
  background: #ffffff;
  color: #0626d7;
  box-shadow: var(--buttonBoxShadow);
  border-radius: 42px;
  font-weight: 600;
  font-size: 21px;
  min-width: 150px;

  &:hover {
    box-shadow: 1px -7px 25px rgba(249, 21, 140, 0.4), 0 4px 45px rgba(0, 0, 0, 0.25);
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.blueBtn {
  background: radial-gradient(farthest-corner at 100% 0%, #00f4ff 0%, #0626d7 100%);

  &:hover {
    box-shadow: 0 -4px 25px rgba(47, 246, 255, 0.25);
  }

  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      radial-gradient(100% 100% at 100% 0%, #00f4ff 0%, #0626d7 100%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}
