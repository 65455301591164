.pageContainer {
  padding: 140px 0 160px;
  color: #ffffff;
  background: var(--faqsBlueGradient);
}

.pageSubtitle {
  width: 100%;
  max-width: 308px;
  margin: 20px auto 0;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
}

.pageTitle {
  font-family: Poppins;
  font-size: clamp(35px, 8vw, 60px);
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}

.pageDescription {
  text-align: center;
  margin-top: 20px;
  font-size: clamp(15px, 3vw, 22px);

  span {
    display: block;
  }
}

.sectionContainer {
  position: relative;
  margin-top: 79px;
}

.sectionWrapper {
  margin: 0 auto;
  max-width: 900px;

  :global {
    section {
      border-bottom: 0 !important;
    }

    .faq-row-wrapper {
      border-radius: 20px;
      padding: 50px 15px;
    }

    .faq-body {
      max-width: 730px;
      margin: 0 auto;
    }

    .row-title {
      box-shadow: 0px 4px 33px rgba(4, 12, 83, 0.25);
      border-radius: 50px;
      font-size: clamp(17px, 3vw, 20px) !important;
      font-weight: 700;
      padding: 15px 25px 15px 30px !important;
      position: relative;
      align-items: center;
      position: relative;
    }

    .faq-row {
      flex-direction: column;
      position: relative;
      margin-bottom: 12px;
    }

    .row-content-text {
      padding-left: 30px !important;
      padding-right: 50px !important;
      line-height: 1.5;
      font-size: clamp(15px, 3vw, 16px) !important;
    }

    .icon-wrapper {
      position: relative !important;
      top: 0 !important;
      right: 0 !important;

      svg {
        width: 25px !important;
      }
    }
  }
}

.underline {
  text-decoration: underline;
  color: #7176f7;
}
