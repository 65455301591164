.title {
  margin-top: 90px;
  padding: 50px 20px 0;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: clamp(40px, 5.5vw, 60px);
  text-align: center;
  background: var(--darkBlueGradient);
  color: transparent;
  background-clip: text;
}

.sectionContainer {
  position: relative;
  width: 100%;
  padding-bottom: 100px;
}

.sectionColumns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;

  p {
    margin: 20px 0 40px;
    font-family: figtree, sans-serif;
    font-weight: 500;
    font-size: clamp(18px, 3.5vw, 20px);
    text-align: center;
  }
}

.sectionBackground {
  position: absolute;
  width: 100%;
  height: 114%;
  bottom: 0;
  left: 0;
  background-image: url("../../../assets/images/products-background-mobile.svg");
  background-size: 100% 100%;
  z-index: -1;

  @media screen and (min-width: 1200px) {
    background-image: url("../../../assets/images/products-background.svg");
  }
}

.overlap {
  margin-top: -258px;
  padding-top: 138px;
}

.sectionColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  width: 100%;
  /* margin-top: 25px; */
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: clamp(30px, 4.5vw, 40px);
  text-align: center;

  @media screen and (min-width: px) {
    display: inline;
  }
}

.colouredSubtitle {
  background: var(--faqsBlueGradient);
  color: transparent;
  background-clip: text;
}

.imageWrapper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
  }
}

.mobilesImageWrapper {
  max-width: 757px;
  max-height: 757px;
}

.paymentImageWrapper {
  max-width: 539px;
  max-height: 571px;
  padding: 0 20px;
}

.walletTextContainer {
  max-width: 682px;
  padding: 0 20px;
  color: #384f66;
  line-height: 1.6;
}

.paymentTextContainer {
  max-width: 682px;
  margin-top: 20px;
  padding: 0 20px;
  color: #ffffff;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 1200px) {
    margin-top: 0;
    align-items: flex-start;
  }
}

.contactSalesButton {
  padding: 10px 30px;
  min-height: 54px;
  margin-top: -10px;
  margin-bottom: 50px;
  background: radial-gradient(92.68% 346.25% at 1.27% 9.62%, #436cff 4.5%, #a800f7 100%);
  border-radius: 40px;
  font-weight: 600;
  font-size: 21px;
  box-shadow: var(--buttonBoxShadow);
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.6px;

  @media screen and (min-width: 1200px) {
    margin-top: 20px;
  }
}

.swapTextContainer {
  max-width: 682px;
  padding: 0 20px;
  color: #384f66;
  line-height: 1.6;

  h2 {
    margin-top: 30px;
    background-image: radial-gradient(73.16% 257.01% at 25.89% 54.51%, #436cff 4.5%, #1524a5 100%);
    text-align: left;

    @media screen and (min-width: 1200px) {
      margin-top: initial;
      margin-bottom: 30px;
    }
  }

  p {
    text-align: left;

    @media screen and (min-width: 1200px) {
      text-align: center;
    }
  }
}

.kPoolContainer {
  margin-top: 50px;

  @media screen and (min-width: 1200px) {
    padding-left: 115px;
  }

  p {
    margin: 10px 0;
  }
}

.kPoolTitle {
  position: relative;
  display: flex;
  align-items: center;
  font-family: poppins;
  font-size: 26px;
  font-weight: 600;
  color: #0c3967;
  margin-bottom: 10px;
  gap: 5px;

  &::before {
    display: none;
    position: absolute;
    content: "";
    background-image: url("../../../assets/images/blue-lines.png");
    background-size: 100% 100%;
    width: 105px;
    height: 105px;
    top: -48px;
    left: -100px;

    @media screen and (min-width: 1200px) {
      display: block;
    }
  }

  img {
    width: 85px;
    height: 85px;
    aspect-ratio: 1 / 1;
  }
}

.swapImage {
  width: 80%;
  margin-top: 20px;
  max-width: 536px;
  height: auto;
  aspect-ratio: 148 / 199;

  @media screen and (min-width: 1200px) {
    margin-top: initial;
  }
}

@media screen and (min-width: 1200px) {
  .subtitle {
    text-align: left;
  }

  .innerContainer {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sectionColumns {
    flex-direction: row;
    padding: 0 20px;

    p {
      text-align: left;
    }
  }

  .sectionColumn {
    width: 50%;
  }

  .paymentTextContainer {
    max-width: 460px;
    margin-left: -70px;
  }

  .desktopInvertedOrder {
    order: -1;
  }

  .mobilesImageWrapper {
    max-width: 757px;
    max-height: 757px;
    padding-left: 40px;

    @media screen and (min-width: 1200px) {
      transform: translateY(40px);
    }
  }

  .paymentImageWrapper {
    max-width: 562px;
    margin-bottom: 120px;
    padding-top: 90px;

    @media screen and (min-width: 1200px) {
      transform: translateX(40px);
    }
  }

  .sectionBackground {
    height: 109%;
  }
}

.blueRectangle {
  margin-top: -50px;
  width: 100%;
  height: 120px;
  background: radial-gradient(100% 516.4% at 0% 0%, #49f1f7 0%, #0692c5 100%);

  @media screen and (min-width: 1200px) {
    height: 150px;
  }
}

.bold {
  font-weight: 800;
}
