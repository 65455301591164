.includedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 90px 30px;

  h2 {
    font-family: Poppins, sans-serif;
    font-weight: 600;
    font-size: clamp(30px, 5vw, 40px);
    background: var(--blueGradient);
    color: transparent;
    background-clip: text;
    text-align: center;
  }

  p {
    margin: 20px 0 10px;
    font-weight: 600;
    font-size: clamp(21px, 3.5vw, 24px);
    text-align: center;
    color: #455769;
  }
}

.boldText {
  font-weight: 700;
}

.flexBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.featureContainer {
  width: 100%;
  max-width: 546px;
  margin: 40px auto 0;
  padding: 0 20px;

  @media screen and (min-width: 1024px) {
    max-width: 480px;
  }

  img {
    width: 46px;
    height: 46px;
  }

  h3 {
    margin-top: 15px;
    font-weight: 700;
    font-size: clamp(16px, 2.5vw, 20px);
    color: #455769;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: url("../../../../assets/images/listChecked-icon.svg");
  }

  li {
    margin: 15px 0 0 20px;
    font-weight: 400;
    font-size: clamp(16px, 2.5vw, 20px);
    color: #455769;
  }
}
