.bgColoured {
  width: 100%;
  padding: 90px 0 110px;
  color: #ffffff;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.title {
  margin-top: 50px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: clamp(40px, 5.5vw, 60px);
  text-align: center;
}

.subtitle {
  max-width: 308px;
  margin-top: 20px;
  font-weight: 700;
  font-size: clamp(18px, 3.5vw, 20px);
  text-align: center;
}

.sectionBackground {
  position: absolute;
  width: 100%;
  height: clamp(50%, 60vw, 80%);
  top: -40px;
  left: 0;
  background-image: url("../../../assets/images/pricing-background-mobile.svg");
  background-size: 100% 100%;
  z-index: -1;

  @media screen and (min-width: 1200px) {
    background-image: url("../../../assets/images/pricing-background-desktop.svg");
  }
}
