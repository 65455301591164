.stepContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 0 100px;
  color: #ffffff;
  background: var(--mediumBlueGradient);

  @media screen and (min-width: 480px) {
    padding: 50px 0 100px;
  }
}

.innerStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin-top: 30px;
  padding: 0 10px;

  p {
    text-align: center;
    color: #ffffff;
    text-shadow: 0 4px 4px #00000040;
    text-align: left;
    padding-left: 30px;
    margin-top: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.pageTitle {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.messageContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;

  span {
    margin: 0 0 3px 20px;
  }
}

.buttonReupload {
  margin-top: 25px;
}

.buttonNext {
  margin-top: 40px;
}

.cameraContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.reuploadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 260px;
  height: 60px;
  border-radius: 20px;

  span {
    font-weight: normal;
    text-transform: none;
  }
}

.percentageContainer {
  width: 100%;
  max-width: 300px;
  height: 16px;
  margin-top: 20px;
  background-color: #ededed;
  border-radius: 20px;
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding-right: 10px;
    background-color: #20d4a8;
    font-size: 13px;
  }
}
