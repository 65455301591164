.languageSelector {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 1px 3px #00000017;
  color: #ffffff;
  border-radius: 50px;
  min-width: 150px;
  min-height: 35px;
  background: #11104a;
  border: 2px solid #ffffff;

  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
    width: 100%;
    font-size: 16px;
    padding: 10px 15px 10px 20px;
    text-transform: capitalize;

    @media screen and (min-width: 1024px) {
      padding: 10px 15px 10px 20px;
      font-size: 16px;
    }
  }

  a {
    display: flex;
    align-items: center;
  }
}

.languageSelectorTerms {
  color: initial;
  background: #ffffff;
  border: initial;

  button {
    color: #6a6a6a;
  }

  .arrowIcon {
    filter: brightness(0.45);
  }
}

.arrowIcon {
  width: 13px;
  aspect-ratio: 15 / 8;
  margin-left: 10px;
}

.flipIcon {
  transform: rotate(180deg);
}

.languageNav {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 100%;
  left: 0;
  box-shadow: inset 0 -1px 3px #00000040;
  border-radius: 7px;
  background: #ffffff;
  cursor: pointer;
  z-index: 999;
}

.languageList {
  list-style-type: none;
  width: 100%;
  padding: 10px 0;
  margin: 0;
  font-size: 18px;

  @media screen and (min-width: 1024px) {
    font-size: 16px;
  }

  li {
    display: flex;
    margin: initial !important;
    padding: 15px 0 10px 30px;
    margin-bottom: 0;
    text-transform: capitalize;
    color: #6a6a6a;

    &:hover {
      background: rgb(238, 238, 238);
    }

    img {
      margin-right: 0;
      top: 0;
    }
  }
}
