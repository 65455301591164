.uploadContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 351px;
  height: 53px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f8fafc;
  border-radius: 20px;
  box-sizing: border-box;

  input[type="file"] {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: 351px;
    height: 53px;
    display: none;
    border-radius: 20px;
  }

  label {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 351px;
    height: 53px;
    padding-left: 80px;
    font-weight: 600;
    font-size: 14px;
    color: #11152b;
    border-radius: 20px;
    cursor: pointer;
  }

  span {
    margin: 0 5px;
    color: var(--primaryPink);
  }

  img {
    position: absolute;
    top: 12px;
    left: 20px;
    cursor: pointer;
  }
}
