.container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 351px;
  height: 53px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #21D4A9;
  border-radius: 20px;

  span {
    margin-left: 20px;
  }
}
