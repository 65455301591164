.contentWrapper {
  max-width: var(--homeWidthContainer);
  margin: 0 auto;
  padding: 0 30px 30px;

  @media screen and (min-width: 1100px) {
    padding-top: 60px;
  }
}

.sectionTitle {
  width: clamp(200px, 30vw, 100%);
  font-size: clamp(30px, 5vw, 40px);
  background: var(--faqsBlueGradient);
  color: transparent;
  background-clip: text;
}

.sectionSubtitle {
  max-width: clamp(280px, 0.4vw, 400px);
  font-size: clamp(24px, 3vw, 34px);
  color: #7a87ff;

  @media screen and (min-width: 700px) {
    max-width: 100%;
  }
}

.itemsContainer {
  width: 100%;
  max-width: 1024px;
  margin: 60px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.listItem {
  list-style: none;
  width: 100%;
  max-width: 500px;

  @media screen and (min-width: 800px) {
    width: 45%;
  }

  h4 {
    font-weight: 500;
    font-size: clamp(20px, 3.5vw, 25px);
    color: #0c3967;
  }

  p {
    font-size: clamp(18px, 3.5vw, 20px);
    line-height: 1.6;
    color: #52687e;
  }
}

.itemHeader {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: -20px;
}

.itemDescription {
  margin-top: 15px;
}
