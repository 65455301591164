.container {
  position: relative;
  width: 100%;
  padding: 150px 20px 130px;
}

.title {
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: clamp(40px, 5.5vw, 60px);
  background: var(--purpleGradient);
  color: transparent;
  background-clip: text;
  text-align: center;
}

.contentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

.sectionBackground {
  position: absolute;
  width: 100%;
  height: 60%;
  left: 0;
  bottom: 0;
  background-image: url("../../../assets/images/contactSales-background-mobile.svg");
  background-size: 100% 100%;
  z-index: -1;

  @media screen and (min-width: 1200px) {
    background-image: url("../../../assets/images/contactSales-background-desktop.svg");
  }
}

.formWrapper {
  width: 100%;
  padding: 21px;
  background-color: #ffffff;
  box-shadow: 0 4px 33px rgba(4, 12, 83, 0.25);
  border-radius: clamp(15px, 5vw, 20px);

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 430px;
    margin-left: auto;
    margin-right: auto;
  }
}

.btn {
  width: 188px;
  height: 54px;
  margin: 30px 0 20px;
  font-weight: 600;
  font-size: 21px;
  color: #ffffff;
  background: var(--blueGradient);
  box-shadow: var(--buttonBoxShadow);
  border-radius: 42px;
}

.recaptchaContainer {
  margin-top: 20px;
}

.errorMessage {
  margin-left: -40px;
  margin-top: 3px;
  color: red;
  font-size: 11px;
}

.correctMessage {
  display: block;
  margin-top: 3px;
  margin-left: 15px;
  font-size: 11px;
  color: green;
}

.kubePayCan {
  width: 100%;
  margin-top: -30px;
  padding: 92px 43px 40px;
  background: var(--lightBlueGradient);
  box-shadow: 0 4px 33px rgba(4, 12, 83, 0.25);
  border-radius: 20px;
  z-index: -1;

  h2 {
    width: clamp(200px, 30vw, 266px);
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: clamp(24px, 3.5vw, 34px);
    line-height: 34px;
    color: #f7f7f7;
  }

  li {
    margin-top: 40px;
    list-style: none;
    font-weight: 600;
    font-size: clamp(16px, 2.5vw, 18px);
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #ffffff;
    position: relative;
    min-height: 34px;

    &::before {
      content: url("../../../assets//images/kubeListBulletPoint-icon.svg");
      position: absolute;
      height: 100%;
      display: flex;
      align-items: center;

      @media screen and (min-width: 1024px) {
        align-items: flex-start;
        top: 7px;
      }
    }
  }

  span {
    display: block;
    margin-left: 52px;
  }
}

.innerCanContainer {
  width: 100%;
  max-width: 428px;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .contentBox {
    flex-direction: row;
    align-items: stretch;
    max-width: 1000px;
    margin: 64px auto 20px;
  }

  .formWrapper {
    max-width: 496px;
  }

  .btn {
    margin-top: 40px;
  }

  .kubePayCan {
    max-width: 400px;
    margin-top: 0;
    margin-left: -30px;
    padding-left: 57px;
  }

  .innerCanContainer {
    max-width: 270px;
  }
}
