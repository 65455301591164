.actionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 30px 130px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: clamp(21px, 4.5vw, 24px);
  line-height: 41px;
  color: #ffffff;
  background: radial-gradient(100% 516.4% at 0% 0%, #49F1F7 0%, #0692C5 100%);
  border-top-left-radius: clamp(20px, 5vw, 75px);
  border-top-right-radius: clamp(20px, 5vw, 75px);
  text-align: center;

  p {
    max-width: 500px;
    margin: 0 auto;
  }

  span {
    font-weight: 600;
    background: var(--purpleGradient);
    color: transparent;
    background-clip: text;
  }
}
