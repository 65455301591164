.pageContainer {
  width: 100%;
  background: linear-gradient(80deg, #0a46e0, #1ed6ff);
  padding: 150px 5px;

  @media screen and (min-width: 500px) {
    padding: 150px 20px;
  }
}

.pageWrapper {
  width: 100%;
  max-width: 1100px;
  padding: 60px 25px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 4px 33px rgba(4, 12, 83, 0.25);
  border-radius: 20px;
  color: #333333;
  position: relative;

  @media screen and (min-width: 700px) {
    padding: 60px 60px;
  }

  @media screen and (min-width: 1000px) {
    padding: 60px 100px;
  }

  h1 {
    margin-top: 20px;
  }

  h2 {
    margin-top: 50px;
  }

  h3 {
    margin-top: 30px;
  }

  p {
    margin: 20px 0;
    line-height: 20px;
  }

  span {
    font-weight: 600;
  }

  ul li {
    margin: 15px 0 10px 30px;
  }

  a {
    color: #0076e2;
  }
}

.languageSelectorContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}
