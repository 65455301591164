.container {
  display: flex;
  gap: 30px;
  width: 100%;
  margin-top: 30px;

  input[type="checkbox"] {
    position: relative;
    display: grid;
    place-content: center;
    height: 25px;
    width: 25px;
    margin: 0;
    background-color: transparent;
    appearance: none;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    height: 25px;
    width: 25px;
    display: inline-block;
    border-radius: 10px;
    background-color: #ffffff;
  }

  input[type="checkbox"]:checked::before {
    background: radial-gradient(100% 100% at 100% 0%, #00f4ff 0%, #0626d7 100%);
  }

  input[type="checkbox"]::after {
    position: absolute;
    top: 6px;
    left: 6px;
    content: none;
    height: 25px;
    width: 25px;
    display: inline-block;
  }

  input[type="checkbox"]:checked::after {
    content: url("../../../../../../assets/images/checked-icon.svg");
  }

  label {
    text-align: left;
    opacity: 0.85;
  }
}
