.formLine {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 430px;
  margin-top: 18px;
}

.labelPosition {
  align-items: flex-start;

  label {
    margin-top: 6px;
  }
}

.label {
  margin-top: 8px;
  font-weight: 600;
  font-size: clamp(12px, 3.5vw, 18px);
  letter-spacing: -0.02em;
  color: #324960;
}

.input {
  width: clamp(200px, 60vw, 300px);
  height: clamp(27px, 10vw, 39px);
  padding: 10px 16px;
  font-weight: 400;
  font-size: clamp(11px, 2.5vw, 16px);
  letter-spacing: -0.02em;
  color: #52687e;
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(21, 42, 115, 0.15);
  border-radius: 100px;
  border: none;

  &::placeholder {
    color: #52687e;
    opacity: 0.6;
  }
}

.textarea {
  height: clamp(56px, 10vw, 79px);
  font-weight: 400;
  border-radius: clamp(15px, 3.5vw, 20px);
}

.phoneDiv {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 10;
}

.phoneBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 12px;
  font-weight: 400;
  font-size: clamp(11px, 2.5vw, 16px);
  color: #52687e;
  border-right: 1px solid #d0d0d0;
  height: 100%;
  min-width: 58px;
}

.selectionBtn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectionInput {
  position: relative;
  width: 100%;
  font-weight: 400;
  font-size: clamp(11px, 2.5vw, 16px);
  letter-spacing: -0.02em;
  color: #52687e;
  border: none;

  &::placeholder {
    color: #52687e;
    opacity: 0.6;
  }
}

.overlay {
  position: relative;
  z-index: 100;
}

.phoneInput {
  width: 100%;
  padding-left: 12px;
  font-weight: 400;
  font-size: clamp(11px, 2.5vw, 16px);
  letter-spacing: -0.02em;
  color: #52687e;
  border: none;
  z-index: 10;

  &::placeholder {
    color: #52687e;
    opacity: 0.6;
  }
}

.selectorIcon {
  width: 10px;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
}

.selectionArrow {
  width: 10px;
  height: 100%;
}

.selectorOptions {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 50px 0 15px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: -1;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    padding: 10px 10px 10px 24px;

    &:hover,
    &:active {
      background-color: #f6f6f6;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 300;
    font-size: clamp(11px, 2.5vw, 16px);
    letter-spacing: -0.02em;
    color: #52687e;
    text-align: left;
  }

  span {
    font-weight: 400;
  }
}

.selectorOptionsPhone {
  width: 69px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 40px 0 15px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: -1;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    width: 100%;
    text-align: left;

    &:hover,
    &:active {
      background-color: #f6f6f6;
    }
  }

  button {
    width: 100%;
    padding: 10px 10px 10px 20px;
    text-align: left;
  }
}

.errorMessage {
  display: block;
  margin-top: 3px;
  margin-left: 15px;
  font-size: 11px;
  color: red;
}
