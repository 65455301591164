.requirementContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 30px;
  background: radial-gradient(100% 100% at 100% 0%, rgba(0, 244, 255, 0.2) 0%, rgba(6, 38, 215, 0.2) 100%);
  border-radius: 20px;
  margin-top: 20px;

  p {
    width: 100%;
    text-align: left;
  }

  ul {
    width: 100%;
    margin: 0;
    padding-left: 20px;
    list-style-image: url("../../../../../assets/images/blueListMarker-icon.svg");
  }

  li {
    margin-top: 5px;
    padding-left: 10px;
    text-align: left;
  }
}
