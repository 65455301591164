.btn {
  margin-top: 50px;
  padding: 14.5px 29.5px;
  background-color: #ffffff;
  box-shadow: var(--buttonBoxShadow);
  border-radius: 42px;
  font-weight: 600;
  font-size: 21px;

  span {
    background: var(--purpleGradient);
    color: transparent;
    background-clip: text;
  }
}
