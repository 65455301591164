.stepContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 0 100px;
  color: #ffffff;
  background: var(--mediumBlueGradient);

  @media screen and (min-width: 480px) {
    padding: 50px 0 100px;
  }
}

.innerStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin-top: 30px;
  padding: 0 10px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.pageTitle {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.paragContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  padding-left: 30px;
  text-shadow: 0 4px 4px #00000040;
}

.messageContainer {
  margin-top: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  span {
    margin: 0 0 3px 20px;
  }
}

.reuploadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 260px;
  height: 60px;
  margin-top: -20px;
  border-radius: 20px;

  span {
    font-weight: normal;
    text-transform: none;
  }
}
