.header {
  position: absolute;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  padding: 10px clamp(20px, 5vw, 30px) 10px clamp(20px, 5vw, 30px);
  background: #ffffff;
  z-index: 999;
  box-shadow: 0 0 63px rgba(12, 68, 176, 0.25);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.headerWrapper {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.logoWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.logo {
  width: 100%;
  max-width: 210px;
  aspect-ratio: 271 / 88;

  @media screen and (min-width: 700px) {
    max-width: 271px;
  }
}

.headerMenuWrapper {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #000000db;
  position: fixed;
  gap: clamp(20px, 4vw, 60px);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: initial;
    justify-content: space-between;
    position: initial;
    background: initial;
    width: initial;
    height: initial;
    max-width: 900px;
    flex: 1;
  }
}

.menuActive {
  display: flex;
}

.navContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  @media screen and (min-width: 1024px) {
    flex: 1;
    margin-top: initial;
  }
}

.linksContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 650px;
  margin-top: 30px;

  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    flex-direction: initial;
    margin-top: initial;
  }
}

.link {
  margin: 16px 0;
  list-style-type: none;
  font-weight: 700;

  a {
    text-decoration: none;
    color: #ffffff;
    display: flex;
    justify-content: center;
    font-family: figtree;
    font-weight: 600;
    font-size: 26px;

    @media screen and (min-width: 1024px) {
      color: #6e6dab;
      font-size: 20px;
    }
  }
}

.linkActive a {
  display: inline;
  background: var(--purpleGradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contactButton {
  min-width: 188px;
  min-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background: var(--purpleGradient);
  border-radius: 30px;
  color: #ffffff;
  font-size: 21px;
  box-shadow: var(--buttonBoxShadow);
}

.menuButton {
  z-index: 10;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.menuButtonActive {
  filter: invert(1) brightness(200%);
}

.headerHome {
  @media screen and (min-width: 1020px) {
    background: transparent;
    box-shadow: none;
  }

  .link a {
    color: #ffffff;
  }

  .linkActive a {
    display: inline;
    background: #99e7ff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .contactButton {
    background: #ffffff;

    span {
      font-weight: 600;
      display: inline;
      background: var(--purpleGradient);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  /*   .menuButtonActive img {
    filter: invert(1) brightness(200%);
  } */
}
