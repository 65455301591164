.stepContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 0 0 100px;
  color: #ffffff;
  background: var(--mediumBlueGradient);

  @media screen and (min-width: 480px) {
    padding: 50px 0 100px;
  }
}

.innerStepContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 30px;
  padding: 0 10px;

  h3 {
    font-family: Poppins, sans-serif;
    font-size: 25px;
    font-weight: 600;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.25), 0 0 25px rgba(47, 246, 255, 0.3);
    text-align: center;
  }

  form {
    width: 95%;
    margin-top: 20px;
  }

  label {
    display: none;
  }
}

.upperWrapper {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.text {
  color: #ffffff;
  font-size: 16px;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  text-align: left;
}

.transactionDetails {
  width: 95%;
  min-width: 280px;
  padding: 10px 20px 20px;
  margin: 35px auto;
  font-weight: 400;
  background: #f7f7f71a;
  border-radius: 30px;
  font-size: 15px;

  @media screen and (min-width: 480px) {
    width: 90%;
    padding: 10px 35px 20px;
  }
}

.detail {
  margin-top: 10px;
  color: #fdfdfd;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 480px) {
    flex-direction: row;
  }
}

.detailName {
  display: inline-block;
  color: #2ff6ff;
  min-width: 140px;
}

.address {
  word-break: break-all;
  font-weight: 400;
}

.inputWrapper {
  display: flex;
  gap: 20px;
  margin-top: 20px;

  div {
    width: auto;
    margin: 0;
  }
  a {
    margin-left: 5px;
    color: #ffffff;
    text-decoration: underline;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
