.messageBox {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 10px auto;
  padding: 12px 20px;
  background: #f5f8fb;
  border-radius: 30px;

  img {
    width: 15px;
    height: auto;
    margin-right: 8px;
  }

  span {
    color: #3e3e3e;
    font-weight: 600;
    font-size: 12px;
  }
}
