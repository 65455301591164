.infoBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: -70px auto 0;
  padding: 0 20px;
}

.upperBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 546px;
  padding: 30px 10px 50px;
  background-color: #ffffff;
  box-shadow: 0 4px 33px rgba(4, 12, 83, 0.25);
  border-radius: 20px;

  h2 {
    font-weight: 600;
    font-size: clamp(30px, 4.5vw, 40px);
    background: var(--darkBlueGradient);
    color: transparent;
    background-clip: text;
  }
}

.infoText {
  width: 100%;
  max-width: clamp(350px, 60vw, 400px);
  margin: 10px auto;
  font-weight: 600;
  font-size: clamp(16px, 2.3vw, 20px);
  line-height: 30px;
  color: #455769;
  text-align: center;

  @media screen and (min-width: 900px) {
    text-align: justify;
  }
}

.featureList {
  margin: 20px 10px 0 10px;
  list-style-type: none;

  @media screen and (min-width: 400px) {
    margin: 20px 30px 0 35px;
  }

  @media screen and (min-width: 1024px) {
    padding-bottom: 30px;
  }

  li {
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: #52687e;
    font-weight: 400;
    font-size: clamp(16px, 2.3vw, 18px);
    line-height: 30px;
  }

  img {
    width: clamp(15px, 4.5vw, 23px);
    height: clamp(15px, 4.5vw, 23px);
    margin-right: 13px;
  }
}

.lowerBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 546px;
  margin-top: -30px;
  padding: 60px 10px 47px;
  color: #ffffff;
  background: var(--purpleGradientInverted);
  box-shadow: 0px 4px 33px rgba(4, 12, 83, 0.25);
  border-radius: 20px;
  z-index: -1;

  h2 {
    font-family: Poppins, sans-serif;
    font-weight: 700;
    font-size: 24px;
  }

  h3 {
    margin-top: 20px;
    font-weight: 600;
    font-size: clamp(18px, 3.5vw, 20px);
    text-align: center;
  }

  p {
    margin-top: 17px;
    font-weight: 400;
    font-size: clamp(18px, 4.5vw, 20px);
    line-height: 30px;
    text-align: center;
  }
}

.innerLowerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}

@media screen and (min-width: 1024px) {
  .infoBox {
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
  }

  .upperBox {
    z-index: 4;
  }

  .lowerBox {
    max-width: 358px;
    margin-top: 0;
    margin-left: -30px;
    padding-left: 40px;
    z-index: 3;

    h3,
    p {
      max-width: 240px;
      text-align: left;
    }
  }

  .innerLowerContainer {
    align-items: flex-start;
  }
}
