.sectionWrapper {
  position: relative;
  padding: 30px 0 0;
  margin-top: 40px;
  height: clamp(600px, 115vw, 700px);

  @media screen and (min-width: 1100px) {
    height: clamp(800px, 80vw, 850px);
  }
}

.sectionBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../../../assets/images/home-middle-background-small.svg");
  background-size: 100% 100%;
  z-index: -1;

  @media screen and (min-width: 1100px) {
    background-image: url("../../../../assets/images/home-middle-background-medium.svg");
  }
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 0;
  max-width: var(--homeWidthContainer);
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.textContainer {
  color: #ffffff;
  max-width: 320px;
  padding-top: 20px;

  @media screen and (min-width: 600px) {
    margin-left: clamp(50px, 6vw, 150px);
  }

  @media screen and (min-width: 1100px) {
    padding-top: 100px;
  }

  h2 {
    font-size: clamp(30px, 6.5vw, 40px);
    line-height: 1.3;
    margin-bottom: 20px;
    max-width: 300px;
  }

  p {
    font-size: clamp(18px, 3.5vw, 20px);
    width: 100%;
    line-height: 1.7;
  }
}

.imagesContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
}

.mapBackground {
  width: 100%;
  max-width: clamp(600px, 60vw, 970px);
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 90%,
    96% 100%,
    55% 100%,
    51% 96%,
    49% calc(100% - 30px),
    48% calc(100% - 43px),
    43% calc(100% - 50px),
    0% calc(100% - 50px),
    0% 0%
  );

  @media screen and (min-width: 500px) {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 90%,
      96% 100%,
      55% 100%,
      51% 96%,
      49% calc(100% - 30px),
      48% calc(100% - 44px),
      43% calc(100% - 55px),
      0% calc(100% - 55px),
      0% 0%
    );
  }

  @media screen and (min-width: 650px) {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 90%,
      96% 100%,
      55% 100%,
      51% 100%,
      49% calc(100% - 0px),
      48% calc(100% - 0px),
      43% calc(100% - 48px),
      0% calc(100% - 60px),
      0% 0%
    );
  }

  @media screen and (min-width: 1100px) {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%, 30% calc(100% - 138px), 0% calc(100% - 138px), 0% 0%);
  }
}
