$desktop-width: 1000px;

footer {
  position: relative;
  width: 100%;
  margin-top: -50px;
  background: #11104a;
  box-shadow: 0px -10px 33px rgba(4, 12, 83, 0.25);
  padding: 50px 15px;
  z-index: 10;
}

.sectionsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  flex-wrap: wrap;
  gap: 25px;
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  padding: 0 10px;

  @media screen and (min-width: $desktop-width) {
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 750px;
  }
}

.column {
  @media screen and (min-width: 340px) {
    margin-left: 20px;
  }

  @media screen and (min-width: $desktop-width) {
    margin-left: initial;
  }

  ul {
    margin-top: 10px;
  }
}

.logoColumn {
  grid-column: 1/3;
  grid-row: 2/3;
  margin: 0 auto;

  @media screen and (min-width: $desktop-width) {
    grid-column: 1/4;
    grid-row: 1/2;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.logoImg {
  max-width: 250px;

  @media screen and (min-width: $desktop-width) {
    max-width: 340px;
  }
}

.socialNetworksColumn {
  grid-column: 1/3;
  grid-row: 1/2;
  width: 100%;
  margin: -20px auto 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;

  @media screen and (min-width: $desktop-width) {
    grid-column: 1/2;
    grid-row: 2/3;
    gap: 0 20px;
    width: 180px;
    max-width: 180px;
    height: fit-content;
    margin: 0;
  }

  svg {
    fill: #ff6e46;
    width: 30px;
    transition: transform 50ms;
  }

  svg:hover {
    transform: scale(1.15);
  }
}

.hideMobile {
  display: none;

  @media screen and (min-width: $desktop-width) {
    display: block;
  }
}

.ecosystemColumn {
  grid-column: 1/2;
  grid-row: 3/4;
  width: 100%;
  max-width: 350px;

  @media screen and (min-width: $desktop-width) {
    width: 280px;
    grid-column: 1/2;
    grid-row: 3/4;

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 100px;
    }
  }
}

.websiteColumn {
  grid-column: 2/3;
  grid-row: 3/4;

  @media screen and (min-width: $desktop-width) {
    margin-left: 15px;
    grid-column: 2/3;
    grid-row: 2/4;
  }
}

.documentationColumn {
  grid-column: 1/2;
  grid-row: 4/5;

  @media screen and (min-width: $desktop-width) {
    grid-column: 3/4;
    grid-row: 2/4;
  }
}

.listTitle {
  width: 100%;
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 16px;
  align-items: center;
  color: #ffffff;
  text-transform: uppercase;
}

.navLinks {
  color: #ffffff;
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 6px 35px;
  font-size: 16px;
  text-align: left;

  @media screen and (min-width: $desktop-width) {
    font-size: 18px;
  }
}

.navLink {
  width: fit-content;
  text-align: left;

  a {
    font-weight: 400;
  }
}

.copyright {
  grid-column: 1/3;
  grid-row: 5/6;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  color: #d3d3d3;
  font-size: 13px;

  @media screen and (min-width: $desktop-width) {
    grid-column: 1/4;
    grid-row: 4/5;
  }

  span {
    display: block;
  }
}
